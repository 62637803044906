vue
<template>
  <div>
    <div class="p-grid">
      <div class="p-col-12 p-d-flex p-jc-center">
        <p class="title-bold">Fisioterapia y suelo pélvico</p>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12 p-d-flex p-jc-center">
        <img class="img-fluid" alt="fisioterapia" src="../../assets/fisioterapia.jpg" />
      </div>
      

      <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
        <Card styleClass="p-card-shadow">
          <template #content>
            <p class="p-text-bold">Beneficios de la Fisioterapia</p>
            <p>La fisioterapia es una disciplina terapéutica que utiliza técnicas manuales y ejercicios para tratar
              diversas condiciones y promover la salud y el bienestar. Algunos de los beneficios de la fisioterapia
              incluyen:</p>
            <ul>
              <li><span class="p-text-bold">Alivio del dolor:</span> La fisioterapia puede ayudar a reducir el dolor en
                áreas como la espalda, el cuello, las articulaciones y los músculos, mediante técnicas como masajes,
                estiramientos y terapia manual.</li>
              <li><span class="p-text-bold">Mejora de la movilidad:</span> Los fisioterapeutas trabajan en mejorar la
                movilidad y la funcionalidad del cuerpo, lo que puede ser especialmente beneficioso para personas que han
                sufrido lesiones o cirugías.</li>
              <li><span class="p-text-bold">Rehabilitación:</span> La fisioterapia desempeña un papel crucial en la
                rehabilitación después de una lesión o cirugía, ayudando a fortalecer los músculos, mejorar la
                flexibilidad y restaurar la función física.</li>
              <li><span class="p-text-bold">Prevención de lesiones:</span> Los fisioterapeutas no solo tratan lesiones
                existentes, sino que también pueden enseñar técnicas de prevención de lesiones y proporcionar
                asesoramiento sobre postura y ergonomía.</li>
              <li><span class="p-text-bold">Promoción del bienestar general:</span> La fisioterapia no solo se enfoca en
                tratar problemas físicos, sino que también puede ayudar a mejorar el bienestar general, reducir el estrés
                y promover un estilo de vida saludable.</li>
            </ul>
            <p>La fisioterapia es una opción terapéutica efectiva y segura para una amplia gama de condiciones. Nuestro
              equipo de fisioterapeutas altamente capacitados está comprometido en brindar atención personalizada y de
              calidad para ayudarte a alcanzar tus objetivos de salud y bienestar. ¡Contáctanos hoy mismo para programar
              una cita y comenzar a disfrutar de los beneficios de la fisioterapia!</p>
              <div class="p-col-12 p-d-flex p-jc-center">
        <img class="img-fluid" alt="suelo pelvico" src="../../assets/suelo-pelvico.jpg" />
      </div>
            <p class="p-text-bold">Transforma tu Bienestar con la Tecnología <span class="p-text-bold">HIFEM</span> para el Suelo Pélvico
            </p>
            <p>¿Sufres de <span class="p-text-bold">incontinencia urinaria</span> o <span class="p-text-bold">debilidad en
                el suelo pélvico</span>? No te preocupes, hay una solución avanzada y no invasiva que puede cambiar tu
              vida: la tecnología <span class="p-text-bold">HIFEM</span>. Como médico especializado en esta innovación,
              quiero ofrecerte información crucial sobre cómo esta terapia puede mejorar tu calidad de vida.</p>
            <p><span class="p-text-bold">HIFEM</span>, o High-Intensity Focused Electromagnetic, es un tratamiento que
              utiliza campos electromagnéticos para <span class="p-text-bold">fortalecer y tonificar los músculos del
                suelo pélvico</span>. Durante las sesiones, sentirás contracciones musculares profundas, similares al
              ejercicio, pero con una intensidad que es imposible lograr por ti mismo.</p>
            <p>Los beneficios son notables: mejora de la <span class="p-text-bold">incontinencia urinaria</span>, <span
                class="p-text-bold">fortalecimiento de los músculos del suelo pélvico</span> y recuperación de tu
              confianza y comodidad. Además, las sesiones son rápidas y sin dolor, sin tiempo de recuperación necesario.
            </p>
            <p>No permitas que los problemas del suelo pélvico controlen tu vida. Nuestra clínica está comprometida con tu
              bienestar, y la tecnología <span class="p-text-bold">HIFEM</span> es la clave para recuperar tu salud.
              Contáctanos hoy mismo para obtener más información y comenzar tu viaje hacia una vida más saludable y
              activa. ¡No esperes más para transformar tu bienestar!</p>
          </template>

          <template #footer>
            <span>Para más información no dude en ponerse en contacto con nuestro
              Centro médico al 637 857 384.</span>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from 'primevue/card';
export default {
  name: 'FisioterapiaVue',
  data() {
    return {
      // Propiedades de datos del componente
    };
  },
  components: {
    Card
  },
  methods: {
    // Métodos del componente
  },
  computed: {
    // Propiedades computadas del componente
  },
  created() {
    // Lógica que se ejecuta cuando el componente se crea
  },
  mounted() {
    // Lógica que se ejecuta cuando el componente se monta en el DOM
  },
  destroyed() {
    // Lógica que se ejecuta cuando el componente se destruye
  },
};
</script>

<style scoped>
.title-bold {
  font-size: 30px;
  font-weight: bold
}

.img-fluid {
  width: 100%;
  height: auto;
}

@media (min-width: 576px) {
  .img-fluid {
    max-width: 50%;
    height: auto;
  }
}
</style>